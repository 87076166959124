import type { Route } from "nextjs-routes";

export interface FolderItemProperties {
  label: string;
  path: Route | string;
  icon?: string;
  submenu?: Array<FolderItemProperties>;
  isExternalLink?: boolean;
}

export const prepaidMenus: Array<FolderItemProperties> = [
  {
    icon: "IconPlus",
    label: "Top Up",
    path: { pathname: "/user/manage/top-up" },
    submenu: [],
  },
  {
    icon: "IconManage",
    label: "Itemisation",
    path: { pathname: "/user/usage" },
    submenu: [],
  },
  {
    icon: "IconHistory",
    label: "History",
    path: { pathname: "/user/manage/topup-history" },
    submenu: [],
  },
  {
    icon: "IconHelp",
    label: "Help",
    path: { pathname: "/help" },
    submenu: [],
  },
];

export const postpaidMenus: Array<FolderItemProperties> = [
  {
    icon: "IconManage2",
    label: "Manage",
    path: { pathname: "/user/manage" },
    submenu: [
      {
        label: "Services",
        path: { pathname: "/user/manage/services" },
      },
      {
        label: "Bolt Ons",
        path: { pathname: "/user/manage/bolt-ons" },
      },
      {
        label: "Plans & Upgrades",
        path: { pathname: "/user/manage/plan" },
      },
      {
        label: "Prepaid Top up",
        path: { pathname: "/user/manage/top-up" },
      },
      {
        label: "Account Settings",
        path: { pathname: "/user/settings" },
      },
    ],
  },
  {
    icon: "IconDocumentText",
    label: "Billing",
    path: { pathname: "/user/bills" },
    submenu: [
      {
        label: "This Month",
        path: { pathname: "/user/bills/month/current" },
      },
      {
        label: "Past Months",
        path: { pathname: "/user/bills/month/past" },
      },
    ],
  },
  {
    icon: "IconManage",
    label: "Itemisation",
    path: { pathname: "/user/usage" },
  },
  {
    icon: "IconHelp",
    label: "Help",
    path: { pathname: "/help" },
    submenu: [],
  },
];

export const onlyPostpaidMenus: Array<FolderItemProperties> = [
  {
    icon: "IconManage2",
    label: "Manage",
    path: { pathname: "/user/manage" },
    submenu: [
      {
        label: "Services",
        path: { pathname: "/user/manage/services" },
      },
      {
        label: "Bolt Ons",
        path: { pathname: "/user/manage/bolt-ons" },
      },
      {
        label: "Plans & Upgrades",
        path: { pathname: "/user/manage/plan" },
      },
      {
        label: "Account Settings",
        path: { pathname: "/user/settings" },
      },
      {
        label: "Prepaid Top up",
        path: { pathname: "/user/manage/top-up/other-number" },
      },
    ],
  },
  {
    icon: "IconDocumentText",
    label: "Billing",
    path: { pathname: "/user/bills" },
    submenu: [
      {
        label: "This Month",
        path: { pathname: "/user/bills/month/current" },
      },
      {
        label: "Past Months",
        path: { pathname: "/user/bills/month/past" },
      },
    ],
  },
  {
    icon: "IconManage",
    label: "Itemisation",
    path: { pathname: "/user/usage" },
  },
  {
    icon: "IconHelp",
    label: "Help",
    path: { pathname: "/help" },
    submenu: [],
  },
];

export const headerMenus: Array<FolderItemProperties> = [
  {
    icon: "",
    label: "My JT",
    path: { pathname: "/user" },
    submenu: [],
  },
  {
    icon: "",
    isExternalLink: true,
    label: "Personal",
    path: "https://www.jtglobal.com/jersey/personal/",
    submenu: [
      {
        isExternalLink: true,
        label: "JT One Bundle",
        path: "https://www.jtglobal.com/jersey/jt-one/",
      },
      {
        isExternalLink: true,
        label: "JT Fibre",
        path: "https://www.jtglobal.com/global/fibre/",
      },
      {
        isExternalLink: true,
        label: "Broadband",
        path: "https://www.jtglobal.com/jersey/broadband",
      },
      {
        isExternalLink: true,
        label: "Pay Monthly",
        path: "https://www.jtglobal.com/jersey/pay-monthly-plans",
      },
      {
        isExternalLink: true,
        label: "Pay As You Go",
        path: "https://www.jtglobal.com/jersey/payg-plans/",
      },
      {
        isExternalLink: true,
        label: "Roaming",
        path: "https://www.jtglobal.com/global/roaming",
      },
      {
        isExternalLink: true,
        label: "Shop",
        path: "https://shop.jtglobal.com/",
      },
      {
        isExternalLink: true,
        label: "JT Apps",
        path: "https://www.jtglobal.com/global/jt-apps/",
      },
      {
        isExternalLink: true,
        label: "5G",
        path: "https://www.jtglobal.com/global/5g/",
      },
      {
        isExternalLink: true,
        label: "Help & Support",
        path: "https://www.jtglobal.com/jersey/jt-help/",
      },
    ],
  },
  {
    icon: "",
    isExternalLink: true,
    label: "Shop",
    path: "https://shop.jtglobal.com/",
    submenu: [
      {
        isExternalLink: true,
        label: "Phones",
        path: "https://shop.jtglobal.com/product-type/smart-phones/",
      },
      {
        isExternalLink: true,
        label: "Tablets",
        path: "https://shop.jtglobal.com/product-type/tablets/",
      },
      {
        isExternalLink: true,
        label: "Gaming",
        path: "https://shop.jtglobal.com/product-type/gaming/",
      },
      {
        isExternalLink: true,
        label: "Speakers & Headphones",
        path: "https://shop.jtglobal.com/product-type/accessories/speakers-headphones/",
      },
      {
        isExternalLink: true,
        label: "Security, Wi-Fi & Landline",
        path: "https://shop.jtglobal.com/product-type/security-wifi/",
      },
      {
        isExternalLink: true,
        label: "Smart Watches & Accessories",
        path: "https://shop.jtglobal.com/accessories/",
      },
    ],
  },
  {
    icon: "",
    isExternalLink: true,
    label: "Enterprise",
    path: "https://business.jtglobal.com/",
    submenu: [
      {
        isExternalLink: true,
        label: "Solutions",
        path: "https://business.jtglobal.com/",
      },
      {
        isExternalLink: true,
        label: "Managed Services",
        path: "https://business.jtglobal.com/managed-services",
      },
      {
        isExternalLink: true,
        label: "Support",
        path: "https://business.jtglobal.com/help-and-support",
      },
      {
        isExternalLink: true,
        label: "EGaming",
        path: "https://business.jtglobal.com/egaming",
      },
      {
        isExternalLink: true,
        label: "Small Businesses",
        path: "https://business.jtglobal.com/small-business-solutions",
      },
    ],
  },
  {
    icon: "",
    label: "International",
    path: "https://international.jtglobal.com/",
    submenu: [
      {
        isExternalLink: true,
        label: "Roaming Solutions",
        path: "https://international.jtglobal.com/sponsored-roaming/",
      },
      {
        isExternalLink: true,
        label: "Fraud Protection",
        path: "https://international.jtglobal.com/fraud-protection-services/",
      },
      {
        isExternalLink: true,
        label: "Messaging",
        path: "https://international.jtglobal.com/enterprise-messaging/",
      },
      {
        isExternalLink: true,
        label: "Support",
        path: "https://jerseytelecomprod.service-now.com/intcsm",
      },
    ],
  },
  {
    icon: "",
    label: "Help & Contact",
    path: { pathname: "/help" },
    submenu: [],
  },
];
