import { gql } from "@/__generated__";

const topupOffersQuery = gql(`
query TopupOffers {
  topup {
    id
    bundles {
      ...TopupOfferProperties
    }
    credits {
      ...TopupOfferProperties
    }
    roaming {
      ...TopupOfferProperties
    }
  }
}
`);

const prepaidServiceQuery = gql(`
  query PrepaidServiceExists($phoneNumber: String!) {
    prepaidServiceExists(phoneNumber: $phoneNumber)
  }
`);

const outOfPlanChargeQuery = gql(`
  query OutOfPlanCharge {
  topup {
      bundles {
        outOfPlanChargeTable {
          rows {
            order
            row
          }
        }
      }
    }
  }
  `);

export { topupOffersQuery, prepaidServiceQuery, outOfPlanChargeQuery };
